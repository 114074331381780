import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router';
import { AppServiceService } from 'src/app/api/app-service.service';
import { StorageService } from 'src/app/core/storage.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.page.html',
  styleUrls: ['./pages.page.scss'],
})
export class PagesPage implements OnInit {
  @Input() slug = null;

  pageContent: any = "";
  title: string = "";

  constructor(private route: ActivatedRoute, public appService: AppServiceService, private storage: StorageService, private sanitized: DomSanitizer) { }

  ngOnInit() {
    // get page details from local storage
    this.storage.get(this.appService.STORAGE_KEYS.PAGES_KEY).then((pages) => {
      let pagesArray = JSON.parse(pages);
      if (this.slug) {
        this.getPageContent(pagesArray, this.slug);
      } else {
        this.route.params.subscribe((param) => {
          this.getPageContent(pagesArray, param.slug);
        })
      }
    });
  }

  /**
   * Find page content by given slug
   * @param pagesArray pages array object
   * @param slug slug to find page content
   */
  getPageContent(pagesArray, slug) {
    // find page details of given slug from route parameters
    let page = pagesArray.find((ob) => ob.slug === slug);
    if (page && page != null) {
      this.title = page.name;
      // dom sanitize the string html content (otherwise styles and other stuff will be removed)
      this.pageContent = this.sanitized.bypassSecurityTrustHtml(page.content);
    }
  }

}
