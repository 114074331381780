import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ToppingsPageRoutingModule } from './toppings-routing.module';

import { ToppingsPage } from './toppings.page';
import { TranslateModule } from '@ngx-translate/core';

import { PipeModule } from '../../../core/pipe.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ToppingsPageRoutingModule,
    TranslateModule,
    PipeModule
  ],
  declarations: [ToppingsPage],
  exports: [ToppingsPage]
})
export class ToppingsPageModule { }
