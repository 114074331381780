import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { CrossSellingPageRoutingModule } from './cross-selling-routing.module';

import { CrossSellingPage } from './cross-selling.page';
import { TranslateModule } from '@ngx-translate/core';
import { PipeModule } from "../../../core/pipe.module"

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    CrossSellingPageRoutingModule,
    TranslateModule,
    PipeModule
  ],
  declarations: [CrossSellingPage],
  exports: [CrossSellingPage]
})
export class CrossSellingPageModule { }
